<template>
  <div id="loading_logo" :class="loading ? '' : 'hidden'" class="bg-white flex absolute top-0 left-0 h-screen w-full justify-center">
    <svg class="animated max-w-[700px]" id="eexnitISYWU1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 815 815" shape-rendering="geometricPrecision" text-rendering="geometricPrecision">
      <g class="opacity-0 animate-delay-1s animate-fadein" id="eexnitISYWU2" transform="matrix(.1 0 0-.1 0 815)">
        <path d="M2070,6765v-145l98-104l97-104l61-1l61-1-49-202c-26-112-80-336-118-498-39-162-106-445-150-629-78-327-80-337-80-461v-127l106-112l106-111l357,2l356,3l3,133l3,133-88,114-88,114-102,1c-84,0-103,3-103,15c0,8,51,234,114,502c293,1249,266,1119,266,1262v128l-90,116-91,117h-334-335v-145Zm620-15c0-121,30,14-344-1573-58-246-106-450-106-452c0-3,101-5,225-5h225v-95-95l-322,2-323,3-3,106c-3,105-2,111,114,595c64,269,167,701,229,959l112,470-190,5-189,5-1,93-2,92h288h287v-110Z" stroke-dasharray="14137.35"/>

        <path d="M3386,6853c-4-32-40-436-81-898-42-462-88-981-104-1153l-28-313l56-53c31-29,84-78,118-109l63-57h129c72,0,132,4,135,8c2,4,12,100,22,212c18,210,33,279,35,154c2-153,1-150,129-268l115-106h133c123,0,132,1,132,19s-44,562-129,1606c-22,264-45,548-52,630l-12,151-105,117-105,117h-223-222l-6-57Zm409-55c3-35,44-531,90-1103s87-1068,90-1102l7-63h-97c-68,0-97,4-100,13-2,6-11,111-19,232-9,121-19,246-22,278l-6,57h-134c-74,0-134-4-134-9c0-15-39-462-46-519l-6-53-96,3c-79,2-97,6-95,18c0,8,48,531,105,1162l103,1148h177h176l7-62Z"/>

        <path d="M3590,6499c0-11-9-123-20-247-46-524-80-918-80-934s11-18,115-18c113,0,115,0,115,23c0,12-20,282-45,601-25,318-45,583-45,587c0,5-9,9-20,9-14,0-20-7-20-21Z"/>

        <path d="M4510,5700v-1211l63-58c34-32,88-81,120-110l58-52l137,3l137,3v116v117l55-48c30-27,86-80,125-119l69-71h148c82,0,148,3,148,7c0,3-11,20-24,37s-34,51-47,76l-24,45-5,455c-5,443-6,456-27,495-12,22-35,56-51,75l-30,35l43,47c26,30,47,64,54,92c7,29,11,171,11,404c0,481,3,473-214,688-87,85-128,119-181,145l-69,34-248,3-248,4v-1212Zm516,1145c66-19,126-72,155-137l24-53v-395c0-367-1-398-19-430-17-32-62-79-77-80-4,0-24-7-45-16l-38-16l34-14c57-25,101-65,124-113c20-44,21-63,26-501l5-456l27-52l27-52h-98c-81,0-101,3-113,18-43,48-43,51-48,502-5,414-6,437-25,470-26,44-72,67-152,76l-63,7-2-534-3-534-102-3-103-3v1166v1165h208c155,0,221-4,258-15Z"/>

        <path d="M4770,6230v-432l53,7c84,11,132,39,159,93c23,46,23,54,23,352c0,273-2,308-18,337-23,43-57,59-144,68l-73,7v-432Z"/>

        <path d="M5962,6778c-7-73-35-380-62-683-27-302-70-776-95-1052s-45-515-45-531c0-26,17-46,117-136l116-106h128c70,0,130,4,133,9s15,104,26,221s21,215,23,217s7-47,10-109l7-113l117-112l118-113h131c72,0,133,2,135,5c3,2-1,71-8,152s-26,315-42,518c-138,1690-142,1729-159,1750-9,11-57,64-106,117l-89,98h-222-221l-12-132Zm412,50c3-18,19-206,36-418c33-414,60-740,115-1412c19-234,35-436,35-447c0-21-4-22-97-19l-98,3-21,285c-12,157-22,286-23,288c0,1-61,2-134,2h-134l-22-262c-12-145-24-275-27-290l-6-28h-94c-93,0-94,0-94,24c0,14,18,222,40,463s67,737,100,1103s63,682,66,703l6,37h173h174l5-32Z"/>

        <path d="M6176,6508c-2-7-25-260-51-563-25-302-49-571-52-597l-6-48h119h119l-47,603c-26,331-47,605-47,610-1,12-31,8-35-5Z"/>

        <path d="M6120,3814c-128-47-224-161-249-299-14-73-14-409-1-489c25-153,84-266,230-436c114-132,164-212,191-302c33-108,23-111-59-19l-74,81h-149-150l3-352l3-353l29-60c38-80,282-324,371-372c162-86,357-58,466,65c83,95,99,167,99,452c0,230-11,341-45,447-32,101-73,168-201,333-112,144-154,211-180,281-16,47-34,169-24,169c3,0,41-36,85-80l80-81l140,3l140,3l3,200c4,327-12,383-154,546-94,108-207,205-288,246-66,34-196,42-266,17Zm270-67c46-23,75-46,102-82c66-87,72-120,73-375v-225l-102-3-103-3v206c0,152-4,214-14,239-38,92-152,103-207,20-17-26-19-48-19-234c0-226,7-270,59-366c18-33,75-113,128-179c241-302,270-394,261-844-6-263-13-292-89-375-68-75-129-100-239-100-71,0-95,4-145,28-69,31-127,83-158,141-21,38-22,54-25,373l-3,332h105h106v-285c0-310,2-321,56-364c59-47,151-11,175,67c7,24,9,126,7,297-4,209-8,270-21,311-22,68-96,177-197,294-110,127-176,228-203,314-20,66-22,90-22,331c0,259,0,260,26,315c87,186,274,255,449,167Z" transform="translate(0-43.35596)"/>

        <path d="M1407,3813c-4-3-7-549-7-1212v-1206l118-107l117-107l138-1h137v82v83l76-83l76-82h143h144l6,48c3,26,8,63,11,82l6,35l76-83l77-83l128,1h127v1203l-1,1202-107,117-107,117-226,1h-226l-6-44c-4-25-9-81-13-126-4-44-10-83-14-85-4-3-31,21-61,53-29,31-84,90-122,130l-69,72h-207c-114,0-211-3-214-7Zm387-55c2-7,36-321,76-698c85-814,100-952,104-965c2-5,6,24,10,65s18,174,31,295c26,238,47,430,103,965l37,345l183,3l182,2v-1165-1165h-99-98l-6,93c-4,50-8,376-9,722-2,347-5,727-8,845l-5,215-68-645c-38-355-80-742-92-860-13-118-27-250-31-292l-7-78h-123-124v28c-1,15-42,441-94,947l-92,920-7-604c-4-332-7-759-7-948v-343h-100-100v1165v1165h170c128,0,172-3,174-12Z" transform="translate(0-60)"/>

        <path d="M3101,2603v-1218l114-102l113-103h141h141v1203l-1,1202-107,117-107,118h-147-148l1-1217Zm249,2v-1165h-105-105v1165v1165h105h105v-1165Z" transform="translate(-5-60)"/>

        <path d="M3931,2603l1-1218l113-103l113-102h286h286v133l-1,132-105,115c-90,99-110,115-140,118l-34,3v950v949l-111,120-111,120h-149-149l1-1217Zm257,95l2-1068h140h140v-95-95h-250-250v1165v1166l108-3l107-3l3-1067Z" transform="translate(0-60)"/>

        <path d="M4920,2606v-1214l114-106l113-106h287h286v133l-1,132-106,118c-95,105-110,117-140,117h-33v950v951l-102,112c-57,62-107,115-113,119-5,4-76,8-157,8h-148v-1214Zm260,94v-1070h140h140v-95-95h-245-245v1165v1165h105h105v-1070Z" transform="translate(-32.5-61)"/>
       </g>

      <path class="animate-15 autograph__path" d="M77.952599,62.900686L736.06544,61.594907v689.451546h-656.807062L77.952599,62.900686Z" transform="matrix(.999705 0 0 1.002635 0.120068-1.070654)" fill="none" stroke="#000" stroke-width="20" stroke-linecap="square" stroke-dasharray="2692.52" stroke-dashoffset="2692.52"/>
    </svg>
  </div>
</template>

<script setup lang="ts">
const state = useCookie('loading-shown', {
  maxAge: 86400,
  default: () => 'false',
})

const route = useRoute()
console.log(route.query)
const loading = typeof route.query['builder.frameEditing'] == 'undefined' && (!state.value || state.value === 'false')

const runLoadingAnimation = () => {
  const logo = document.getElementById('loading_logo')
  const paths = logo.querySelectorAll('.autograph__path')
  if (!paths.length) {
    logo.classList.add('hidden')
    return false
  }

  const main = document.getElementById('site-wrapper')
  main.classList.add('hidden')

  state.value = 'true'

  setTimeout(() => {
    main.classList.remove('hidden')
    logo.classList.add('animate-fadeout')
    setTimeout(() => {
      logo.classList.add('hidden')
    }, 3000)
  }, 3000)
}

if (process.client && loading) {
  runLoadingAnimation()
}
</script>