<template>
  <div
    class="p-2 flex flex-wrap items-center justify-center gap-x-6 gap-y-3 leading-none"
  >
    <NuxtLink
      to="/"
      class=""
      aria-label="Zara Mills homepage"
    >
      <img
        src="/img/square.svg"
        alt="Zara Mills"
        class="h-40 w-auto"
        loading="lazy"
      >
    </NuxtLink>
    <nav v-if="navigation.length > 0" class="ml-auto">
      <ul
        class="flex flex-wrap gap-6 md:gap-10"
      >
        <li class="font-semibold tracking-tight text-slate-800">
          <NuxtLink v-for="item in navigation" :to="item.data.url" class="text-lg font-semibold leading-6 text-gray-900">{{ item.data.text }}</NuxtLink>
        </li>
      </ul>
    </nav>
  </div>
</template>

<script setup lang="ts">
import { fetchEntries } from '@builder.io/sdk-vue'
import appConfig from '~/app.config'

const navigation: object[] = ref([])

navigation.value = await fetchEntries({
    model: 'menu-links',
    apiKey: appConfig.builderKey,
    options: {
        fields: 'data',
    },
}).then((data) => { return data?.results ?? [] })
</script>