<template>
    <div id="site-wrapper" class="text-slate-800 text-lg">
        <Header />
        
        <main>
            <NuxtPage />
        </main>
    
        <Footer />
    </div>
  
    <Loading />
</template>
  