<template>
    <footer class="flex flex-col justify-center items-center bg-black text-white p-6">
        <div class="flex gap-4">
            <a href="https://www.imdb.com/name/nm10149117"><span class="i-fa6-brands-imdb h-10 w-10"></span></a>
            <a href="https://uk.linkedin.com/in/zara-mills-753963b2"><span class="i-fa6-brands-linkedin h-10 w-10"></span></a>
            <a href="https://www.instagram.com/zaramills.art"><span class="i-fa6-brands-instagram h-10 w-10"></span></a>
        </div>
        <p class="my-4">Zara Mills © {{ year }}</p>
    </footer>
</template>

<script setup lang="ts">
const year = computed(() => new Date().getFullYear())
</script>
